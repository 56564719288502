import React from 'react'
import { Link } from 'gatsby'

const CTA = ({ data }) => {
	console.log("CTA Data: ", data)
	return (
		<div className="cta">
			{data.target === true &&
				<a 
					href={data.href}
					target='_blank'
					referrer="no-referrer">
						{data.title}
					</a>
			}
			{!data.target &&
				<Link to={data.href}>
					{data.title}
				</Link>
			}
		</div>
	)
}

export default CTA